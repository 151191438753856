import React from 'react';
import styled from 'styled-components';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import {
  BUTTON_STYLE,
  BREAKPOINT,
  PADDING,
  COLOR,
  ALIGN,
  MARGIN
} from '@latitude/core/utils/constants';
import { Text } from '@latitude/text';
import SvgInline from '@latitude/svg-inline';
import { Heading5 } from '@latitude/heading';
import { Link } from '@latitude/link';
import bgCalculators from '../../images/bg-calculators.svg';

export default ({loans1, motor1, solar}) => {
  const href = loans1 ? '/personal-loan-repayment-calculator1/' : '/personal-loan-repayment-calculator/';
  const title= motor1
  ? 'Loan repayment calculator'
  : solar
  ? 'Solar & Battery repayment calculator'
  : 'Personal loan repayment calculator';
  const OuterBox = styled.div`
    display: flex;
    justify-content: center;
    padding: 24px 16px;
    background-image: url(${bgCalculators});
    background-position: center;
    margin-top: -${MARGIN.M48};
    @media (min-width: ${BREAKPOINT.LG}) {
      padding: 72px 0;
    }
  `;

  const Tile = styled.div`
    width: 100%;
    max-width: 343px;
    padding: ${PADDING.P32};
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${COLOR.WHITE};
  `;

  return (
    <AnalyticsLocationProvider location={title}>
      <OuterBox>
        <Tile>
          <SvgInline name="icon-calc-pl-branded" />
          <Heading5
            align={ALIGN.CENTER}
            color={COLOR.BLACK}
            marginTop={MARGIN.M24}
            marginBottom={MARGIN.M16}
          >
            {title}
          </Heading5>
          <Text
            align={ALIGN.CENTER}
            color={COLOR.BLACK}
            marginBottom={MARGIN.M56}
          >
            What will my repayments be?
          </Text>
          <Link
            href={href}
            button={BUTTON_STYLE.TERTIARY}
          >
            Let&apos;s calculate
          </Link>
        </Tile>
      </OuterBox>
    </AnalyticsLocationProvider>
  );
};
